@import './vendor/normalize';
@import './base/base';


/*  ------------------------------
    Settings
    ------------------------------ */
    // colors
    $bg-color: #1A1A1A;
    $color: #fff;
    $grey: #7d7d7d;
    $red: #F28E8D;
    $orange: #F7C09F;
    $yellow: #F4E2A9;
    $green: #C3DDD2;
    $blue: #99A0E0;
    $purple: #e0a5ff;

    // spacing
    $spacing-unit: 1rem;
    
    // sizing
    $container-max-width: 736px; // 704px + (2 * $spacing-unit)
    // $container-max-width: 1110px; // 1050px + (2 * $spacing-unit)
    $page-max-width: 917px; // 885px + (2 * $spacing-unit)
    // $page-max-width: 1380px; // 1320px + (2 * $spacing-unit)


    // breakpoints 
    $breakpoint-md: 768px;
    $breakpoint-lg: 1080px;
    // $breakpoint-lg: 1024px;
    $breakpoint-xl: 1920px;

    // typo    
    $serif-stack: superclarendon, Lucida, Georgia, serif;
    $monospace-stack: Source Code Pro, monospace, sans-serif;
    $base-stack: Noto Sans, sans-serif;

/*  ------------------------------
    Tools
    ------------------------------ */

@mixin serif-font {
    font-family: $serif-stack;
    font-weight: 700;
    font-style: normal;
    line-height: 1.5; // prevent cropping from background
}

@mixin monospace-font {
    font-family: $monospace-stack;   
}

@mixin base-font {
    font-family: $base-stack;
}

// add bg-color to text elements to cover grid lines
// don't use with block elements (unless you mean it), 
// (use block-bg mixin for block elements)

@mixin text-bg {
    background-color: $bg-color;
    padding: 0.5rem 0.5rem;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
}

// add bg-color to block elements to cover grid lines
// just like text-gb mixin without the padding and negative margins

@mixin block-bg {
    background-color: $bg-color;
}

/*  ------------------------------
    Generic
    ------------------------------ */

// box sizing

html {
    box-sizing: border-box;
}

* {
    &,
	&::before,
	&::after {
        box-sizing: inherit;
	}
}

/*  ------------------------------
    Elements
    ------------------------------ */


body {    
    background-image: url('../../public/img/bg1286.svg');
    background-size: 100% auto;
    background-position: top center;    
    background-repeat: repeat-y;
    shape-rendering: crispEdges;
}

@media screen and (min-width: 1286px) {
    body {        
        background-size: auto;
    }   
}


// responsive font sizes

html {
    background-color: $bg-color;
    font-size: 1.125rem;
    line-height: 1.2;
    color: $color;  

    @include base-font;
}

@media screen and (min-width: $breakpoint-md) {
    html {        
        font-size: 1.125rem; // 18px
    }    
}

@media screen and (min-width: $breakpoint-lg) {
    html {        
        font-size: 1.25rem; // 20px
    }    
}

h1, h2, h3, h4, h5, h6 {
    margin-top: 0;    
    font-size: 1.4rem;    

    @include serif-font;    

    > span {
        @include text-bg;
    }
}

// 1. bg color for grid overlay

p {
    // margin-bottom: 2.4rem;
    margin-bottom: 1.3rem;
    
    @include text-bg;
}

a {
    color: $color;
}

ul li {
    margin-bottom: 0.5rem;
}

ul {
    @include text-bg;
}

/*  ------------------------------
    Objects
    ------------------------------ */

// content wrapper

.o-wrapper {
	display: block;
	width: 100%;
	max-width: $container-max-width;
	margin: 0 auto;	
}

.o-row {
    display: flex;
}

/*  ------------------------------
    Layout
    ------------------------------ */

// .l-bg-wrapper {
//     // max-width: 1920px;
//     // margin: 0 auto;
//     background-image: url('../../public/img/bg.svg');
//     background-size: contain;
//     // background-position: top center;
//     // background-repeat: no-repeat;
// }

// @media screen and (min-width: $breakpoint-xl) {
//     .l-bg-wrapper {
//         max-width: 1920px;
//         margin: 0 auto;        
//         background-size: 1920px auto;        
//         background-repeat: no-repeat;
//     }   
// }

// 1. global page width, page padding

.l-page-wrapper {    
    max-width: $page-max-width; // 2
    margin: 0 auto; // 2    
    padding-right: $spacing-unit; // 2
    padding-left: $spacing-unit; // 2    
}

.l-section {
        margin-bottom: 15rem;
}

.l-header {
    display: flex;
    flex-direction: column;    
    padding-top: 2rem;
    padding-bottom: 2rem;

    nav {
        margin-top: 1rem;
    }
}

@media screen and (min-width: $breakpoint-md) {
    .l-header {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-top: 3.9rem;
        padding-bottom: 3.9rem;

        nav {
            margin-top: 0;
        }
    }
}

.l-content-wrapper {
    padding-top: 4rem;
}


//  footer
//  ------------------------------

.l-footer-wrapper {
    position: relative;
    padding: 1rem 0;
    z-index: 100;

    // add *full-width* background to footer
    // in order to cover grid backround

    &:after {
        content: '';
        display: inline-block;
        z-index: -1;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 100%;
        width: 100vw;
        background-color: $bg-color;   
        border-top: 1px solid $color;
    }    
}

.l-footer-top {
    display: flex;
    flex-direction: column;    
    margin-bottom: 2rem;    

    .logo {
        order: 1;
        margin-top: 2rem;
        align-self: center;
    }

    *:last-child {
        margin-bottom: 0;
        margin-top: 0;
    }

    .contact {
        margin-bottom: 4rem;
    }
}

@media screen and (min-width: $breakpoint-md) {
    .l-footer-top {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 4rem;

        .logo {
            align-self: auto;
            order: 0;
        }

        .contact {
            flex: 1;
            margin-bottom: 0;
        }
    }
}

.l-footer-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.8rem;    
}

//  team
//  ------------------------------

.l-team-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
}

@media screen and (min-width: $breakpoint-md) {
 .l-team-row {    
    justify-content: space-between;
}   
}

.l-team-row {
    &.l-team-row--center {
        display: flex;
        justify-content: center;
    }
}

// item

.l-team-row__item {
    width: 50%;
    padding-left: 0.5rem;
    padding-right: 0.5rem;    
}

@media screen and (min-width: $breakpoint-md) {
    .l-team-row__item {
        max-width: 12rem; // magic number: 
    }   
}

@media screen and (min-width: $breakpoint-md) {
    .l-team-row__item {    
        &.l-team-row__item--down {
            margin-top: 18rem; // magic number: see layout
        }

        // adapt to layout: add padding 

        &.l-team-row__item--padded {
            max-width: 16rem; // magic number: 
            padding: 0 2.5rem;            
        }
    } 

    // change order: display frodo in center

    .l-team-row--order {
        .l-team-row__item:nth-of-type(1) {
            order: 0;
        }
        .l-team-row__item:nth-of-type(2) {
            order: 2;
        }
        .l-team-row__item:nth-of-type(3) {
            order: 1;
        }
    }
}

/*  ------------------------------
    Components
    ------------------------------ */

//  faq
//  ------------------------------

// .faq {
//     @include block-bg;   
// }

// .faq_q { 
//     font-style: italic;
// }

// .faq_a {
//     margin: 0 0 2rem 0;

//     &:last-of-type {
//         margn-ibottom: 0;
//     }
// }

//  contact
//  ------------------------------

.contact {
    font-size: 0.8rem;
    line-height: 2.5;
}

.contact__pointer {
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: 1.2rem;

    img {
        margin-right: 0.75rem;
        display: none;
    }
}

@media screen and(min-width: $breakpoint-md) {
    .contact {
        text-align: right;        
        
        .contact__pointer img {        
            display: inline-block;
            margin-right: 0.75rem;            
        }    
    }    
}

//  accordion
//  ------------------------------

.accordion__item {
    border: 1px solid $color;
    
    &:not(:first-child) {
        border-top: none;
    }
    
    @include block-bg;
    
    padding: 1.5rem 1.5rem;
}

.accordion__toggle {
    cursor: pointer;
    margin-bottom: 0;
    font-style: italic;
    font-size: 1rem;
    font-weight: normal;

    @include base-font;    
}

.accordion__content {
    overflow:hidden; 
    transition:height 0.3s ease-out;
    height: auto;

    *:last-of-type {
        margin-bottom: 0;
    }
}

.accordion__content[data-collapsed="true"] {
    height: 0;
}

// .accordion-content.default {
//     display: block;
// }

//  logo
//  ------------------------------

.logo {
    width: 5.1333333333rem;  
    background-color: $bg-color;
}

.logo {
    &.logo--md {
        width: 6.7rem;
    }
}

//  sublogo
//  ------------------------------

.sub-logo {
    height: 2rem;

    @include block-bg;
}

//  main nav
//  ------------------------------

.main-nav {
    margin: 0;
    padding: 0;
    list-style-type: none;
    font-size: 0.8rem;    
}

.main-nav__item {
    display: inline-block;
    margin-right: 0.45rem;
}
    
.main-nav__link {
    display: inline-block;
    position: relative;
    text-decoration: none;
    color: $color;

    &:after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 110%;
        left: 10%;
        right: 10%;        
        height: 3px;
        background-color: $color;
    }
}

// modifier

.main-nav__link {
    &.main-nav__link--red {        
        &:after {
            background-color: $red;
        }
        &:hover {
            color: $red;
        }
    }

    &.main-nav__link--blue {
        &:after {
            background-color: $blue;
        }
        &:hover {
            color: $blue;
        }
    }

    &.main-nav__link--green {
        &:after {
            background-color: $green;
        }
        &:hover {
            color: $green;
        }
    }

    &.main-nav__link--orange {
        &:after {
            background-color: $orange;
        }
        &:hover {
            color: $orange;
        }
    }

    &.main-nav__link--yellow {
        &:after {
            background-color: $yellow;
        }
        &:hover {
            color: $yellow;
        }
    }

    &.main-nav__link--purple {
        &:after {
            background-color: $purple;
        }
        &:hover {
            color: $purple;
        }
    }
}

//  meta nav
//  ------------------------------

.meta-nav {
    margin: 0 0 1rem 0;
    padding: 0;
    list-style-type: none;
    // text-align: center;

    li {
        display: inline-block;
        margin-right: 1rem;
    }

    a {
        text-decoration: none;
        font-size: 0.8rem;    

        &:hover {
            text-decoration: underline;
        }
    }
}

@media screen and (min-width: $breakpoint-md) {
    .meta-nav {
        li {
            display: inline-block;
            margin-right: 0.45rem;
        }
    }
}


//  card
//  ------------------------------

.card {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
}

.card__media {
    border: 1px solid $color;

    img {
        display: block; // remove gap at bottom
    }
}

.card__content {    

    @include monospace-font;     
    @include text-bg;       
    padding: 0.5rem 0.5rem 0 0.5rem;
}

.card__title { 
    margin-bottom: 0.35rem;       
    font-family: inherit;        
    font-size: 16px; // no responsive font sizes     
}

.card__text {
    font-size: 16px; // no responsive font sizes    

    ul {           
        margin-top: 0;
        margin-bottom: 0;
        list-style-position: outside;        

    }

    li {
        margin-bottom: 0.5rem;
        margin-left: 0.75rem;
    }
}


/*  ------------------------------
    Utilities
    ------------------------------ */

.u-heading {    
    margin-bottom: 2.5rem;    
    font-size: 2.1333333333rem;    

    @include serif-font;

    span {
        @include text-bg;
    }
}

.u-serif {
    @include serif-font;
}

.u-monospace {    
    @include monospace-font;  
}

.u-monospace-bold {    
    font-weight: 700;

    @include monospace-font;  
}

.u-mt {
    margin-top: 3.6rem;
}