// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

//  --------------------
//  box model
//  --------------------

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

//  --------------------
// responsive images
//  --------------------

img {
  max-width: 100%;
  height: auto;
}

//  --------------------
// font smoothing
//  --------------------

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}